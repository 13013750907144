export const SET_META = "SET_META";
export const SET_TOOLTIP = "SET_TOOLTIP";
export const SET_ADD_PROFILE_MODAL = "SET_ADD_PROFILE_MODAL";
export const SET_ADD_TAG_MODAL = "SET_ADD_TAG_MODAL";
export const SET_ALERT_MODAL = "SET_ALERT_MODAL";
export const SET_LOADING_MODAL = "SET_LOADING_MODAL";

export const SEARCH_RESULT_LIMIT = 10;

export const STATUS_ACTIVE = 1;
export const STATUS_REMOVED = 0;

export const PUBLISH_STATUS_PUBLISHED = 1;
export const PUBLISH_STATUS_REMOVED = 2;

export const MAX_ONCE_FILE_LIMIT = 4;

export const GROUP_LOCAL_ADMIN = 3;
export const GROUP_COUNTRY_ADMIN = 4;

export const LEADERSHIP_COUNTRY = 2;

export const COUNTRY_ID = {
  "usa": 2,
  "canada": 6
}

export const URLS = {
  HOMEPAGE: "home",
  PROFILE: {
    ROOT: "/profile",
    DETAIL: "/profile/:brokerId",
    FORM: "/profile/:brokerId/edit",
    LOG: "/profile/:brokerId/log",
    PHOTO: "/profile/:brokerId/photo",
    PHOTO_TUTORIAL: "/profile/:brokerId/photo/tutorial",
    PHOTO_GALLERY: "/profile/:brokerId/photo/gallery",
    MANUAL: "/profile/:brokerId/photo/manual"
  },
  METADATA: {
    ROOT: "/metadata",
  },
  LOCATION: {
    ROOT: "/location",
    DETAIL: "/location/:locationId",
    LOG: "/location/:locationId/log",
  },
  USER: {
    ROOT: "/user",
  },
  ACTIVITY: {
    ROOT: "/activity",
  },
};

export const EDITOR_TOOLBAR_OPTIONS = {
  // options: ['inline', 'blockType', 'fontSize']
}

export * from "./api";
export * from "./table";
