import { ArrowBackOutlined, Label } from "@material-ui/icons";
import { SolButton, SolIcon, SolTextField } from "@solstice/sol-react";
import { SET_LOADING_MODAL, STATUS_ACTIVE } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  patchProfileAPI,
  patchProfileAwardAPI,
  postProfileAwardAPI,
} from "services";
import { AwardProps, BrokerProps, OptionProps, ProfileProps } from "types";
import { SelectAutoComplete, Toast } from "ui-atoms";
import { LoadingPage } from "ui-molecules";
import * as Yup from "yup";

interface AwardsProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
  broker?: BrokerProps | null;
  handlePublish?: any;
}

interface AwardItemProps {
  award: any;
  profile?: ProfileProps | null;
  setProfile?: any;
  setAwards?: any;
  index: number;
  awards?: any;
}

const INITIAL_VALUES = {
  award: "",
  organization: "",
  years: [],
  active_status: STATUS_ACTIVE,
};

const validationSchema = Yup.object().shape({
  award: Yup.string().required("This field is required"),
  organization: Yup.string().required("This field is required"),
  years: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
});

const startYear = 1990;
const currentYear = new Date().getFullYear();
const YEAR_OPTIONS = Array.from(
  { length: currentYear - startYear + 2 },
  (v, i) => currentYear + 1 - i
)?.map((value) => ({ label: value, value }));

const AwardItem: React.FC<AwardItemProps> = ({
  award,
  setProfile,
  profile,
  setAwards,
  index,
}) => {
  const [searchParams] = useSearchParams();
  const [postProfileAward] = useApiCall(postProfileAwardAPI);
  const [patchProfileAward] = useApiCall(patchProfileAwardAPI);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "years") {
        formValues = {
          ...formValues,
          [key]: (award as any)?.[key]?.map((year: string | number) => ({
            label: year,
            value: year,
          })),
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (award as any)?.[key] ? (award as any)?.[key] : null,
      };
    });

    setValues({
      ...formValues,
    });
  }, [award]);

  const {
    setFieldValue,
    handleSubmit,
    values,
    setValues,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        if (award?.id) {
          patchProfileAward({
            ...values,
            years: values.years.map((item: OptionProps) => item?.value),
            broker_profile: searchParams?.get("tab"),
            id: award?.id,
          })
            .then((res: any) => {
              if (!res) return;
              const index = (profile?.awards || [])?.findIndex(
                (item) => item?.id?.toString() === award?.id?.toString()
              );
              if (index > -1) {
                let updatedProfile = { ...profile };
                if (!!updatedProfile?.awards?.length) {
                  updatedProfile.awards[index] = res;
                  setProfile(updatedProfile);
                }
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else
          postProfileAward({
            ...values,
            years: values.years.map((item: OptionProps) => item?.value),
            broker_profile: searchParams?.get("tab"),
          })
            .then((res: any) => {
              if (!res) return;
              let updatedProfile = { ...profile };
              if (!updatedProfile?.awards?.length) updatedProfile.awards = [];
              updatedProfile.awards.push(res);
              setProfile(updatedProfile);
            })
            .finally(() => {
              setIsLoading(false);
            });
      } catch (err) {
        setIsLoading(false);
      }
    },
  });

  return (
    <form
      className="w-full flex flex-row items-center space-x-10"
      onSubmit={handleSubmit}
    >
      <div className="w-full grid grid-cols-3 gap-10">
        <SolTextField
          id="award"
          label="Name of the award"
          size="small"
          required
          className="col-span-1 w-full"
          value={values.award}
          onSol-input={(e: any) => {
            setFieldValue("award", e?.detail);
            const newAward = { ...award };
            newAward["award"] = e?.detail;
            setAwards((prev: any) => {
              const data = [...(prev || [])];
              data[index] = newAward;
              return data;
            });
          }}
          onBlur={handleBlur}
          disabled={isLoading}
          errorLabel={touched?.award ? errors?.award : ""}
        />

        <SolTextField
          id="organization"
          label="Name of the awarding organization"
          size="small"
          required
          className="col-span-1 w-full"
          value={values.organization}
          onSol-input={(e: any) => {
            setFieldValue("organization", e?.detail);
            const newAward = { ...award };
            newAward["organization"] = e?.detail;
            setAwards((prev: any) => {
              const data = [...(prev || [])];
              data[index] = newAward;
              return data;
            });
          }}
          onBlur={handleBlur}
          disabled={isLoading}
          errorLabel={touched?.organization ? errors?.organization : ""}
        />

        <SelectAutoComplete
          name={"years"}
          label="List the years when you have received this award"
          isMulti
          options={YEAR_OPTIONS}
          required
          size="sm"
          className="w-full"
          value={values.years}
          onChange={(e) => {
            setFieldValue("years", e);
            const newAward = { ...award };
            newAward["years"] = e?.map((item: OptionProps) => item?.value);
            setAwards((prev: any) => {
              const data = [...(prev || [])];
              data[index] = newAward;
              return data;
            });
          }}
          onBlur={handleBlur}
          error={touched?.years ? errors?.years : ""}
          isDisabled={isLoading}
        />
      </div>
      <div className="min-w-[70px] mt-1.5">
        <SolButton
          className="sol-w-full"
          itemType="submit"
          disabled={!isValid || !dirty || isLoading}
        >
          <span>{award?.id ? "Update" : "Save"}</span>
        </SolButton>
      </div>
    </form>
  );
};

const Awards: React.FC<AwardsProps> = ({
  profile,
  setProfile,
  isLoading,
  broker,
  handlePublish,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [awards, setAwards] = useState<AwardProps[]>([]);
  const [postProfileAward] = useApiCall(postProfileAwardAPI);
  const [patchProfileAward] = useApiCall(patchProfileAwardAPI);

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab")) {
      setAwards([]);
      return;
    }
    setAwards(
      profile?.awards?.filter(
        (item) => item?.active_status === STATUS_ACTIVE
      ) || []
    );
  }, [profile]);

  const handleAction = async () => {
    try {
      if (awards?.length) {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            title: "Updating awards",
          },
        });
        // Save Awards
        let promises: any = [];
        awards?.forEach((award) => {
          if (
            !award?.award?.length ||
            !award?.organization?.length ||
            !award?.years?.length
          )
            return;
          promises.push(
            new Promise(async (resolve) => {
              if (award?.id) {
                const res = await patchProfileAward({
                  ...award,
                  broker_profile: searchParams?.get("tab"),
                  id: award?.id,
                });
                if (!res) return;
                const index = (profile?.awards || [])?.findIndex(
                  (item) => item?.id?.toString() === award?.id?.toString()
                );
                if (index > -1) {
                  let updatedProfile = { ...profile };
                  if (!!updatedProfile?.awards?.length) {
                    updatedProfile.awards[index] = res;
                    setProfile(updatedProfile);
                  }
                }
              } else {
                const res = await postProfileAward({
                  ...award,
                  broker_profile: searchParams?.get("tab"),
                });
                if (!res) return;
                let updatedProfile = { ...profile };
                if (!updatedProfile?.awards?.length) updatedProfile.awards = [];
                updatedProfile.awards.push(res);
                setProfile(updatedProfile);
              }
              resolve("");
            })
          );
        });

        if (!!promises?.length) await Promise.all(promises);
      }

      if (!profile?.licenses?.length) {
        searchParams.set(
          "sub",
          !profile?.licenses?.length ? "license" : "leader"
        );
        setSearchParams(searchParams);
      } else {
        handlePublish();
      }
    } catch (err) {
    } finally {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          title: "",
        },
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="mb-12">
            <h2 className="text-xl mb-1">Awards</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Add any relevant awards and affiliations you hold. This
              information will display on the bottom of your public profile.
            </p>
          </div>
          <div className="flex flex-col space-y-2 mb-10">
            {awards?.map((award: any, idx: number) => (
              <AwardItem
                award={award}
                awards={awards}
                setAwards={setAwards}
                index={idx}
                key={idx}
                setProfile={setProfile}
                profile={profile}
              />
            ))}
            <SolButton
              variant="secondary"
              onSol-click={() => {
                if (awards?.length >= 6) {
                  Toast.warn(
                    "The Awards and Affiliations section should be limited to a maximum of 6 entries."
                  );
                  return;
                }
                setAwards((prev: AwardProps[]) => [
                  ...(prev || []),
                  {
                    award: "",
                    organization: "",
                    years: [],
                    active_status: STATUS_ACTIVE,
                  },
                ]);
              }}
            >
              Add another award <SolIcon icon="add" />
            </SolButton>
          </div>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "markets");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            {!profile?.licenses?.length ? (
              <SolButton variant="outlined" onSol-click={handleAction}>
                Next
                <SolIcon icon="arrow_right_alt" />
              </SolButton>
            ) : (
              <SolButton onSol-click={handleAction}>Save and Publish</SolButton>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Awards;
