import { SolButton, SolIcon } from "@solstice/sol-react";
import {
  SEARCH_RESULT_LIMIT,
  SET_LOADING_MODAL,
  STATUS_ACTIVE,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCountryAPI,
  getMarketAPI,
  getSessionAPI,
  postFirstSessionAPI,
} from "services";
import { SelectAutoComplete, Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import { joinArray, parseJSON } from "utils";

interface PermissionModalProps {
  isOpen: boolean;
  setIsOpen: any;
}

const PermissionModal: React.FC<PermissionModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const isFirst = session?.permissions?.first_session;
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [getSession, , , error] = useApiCall(getSessionAPI, true);
  const [getCountry] = useApiCall(getCountryAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [postFirstSession] = useApiCall(postFirstSessionAPI);

  const [countryValue, setCountryValue] = useState<any>({
    value: undefined,
    touched: false,
  });
  const [marketValue, setMarketValue] = useState<any>({
    value: undefined,
    touched: false,
  });

  const handleEmail = () => {
    const recipient = "profile.management@jll.com";
    const subject = "Profile Management Access Request";
    const body = `Please provide the below user with access to the Profile Management tool for the following market(s):\n\n\nUser email: ${session?.email}\n\nMarket(s): \n\n`;

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink);
  };

  const handleSubmit = async () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Submiting values",
        },
      });
      postFirstSession({
        countries: countryValue?.value?.map((item: any) => item?.value) || [],
        markets: marketValue?.value?.map((item: any) => item?.value) || [],
      })
        .then((res: any) => {
          getSession().then((res: any) => {
            if (!res) {
              sessionStorage.removeItem("session");
              return;
            }
            if (res?.mock_user) {
              sessionStorage.setItem("mainSession", JSON.stringify(res));
              sessionStorage.setItem("session", JSON.stringify(res?.mock_user));
            } else {
              sessionStorage.setItem("session", JSON.stringify(res));
              sessionStorage.removeItem("mainSession");
            }

            if (!!Cookies.get("okta_tokens")) {
              const tokens = parseJSON(Cookies.get("okta_tokens") || "");
              if (tokens && !!Object.keys(tokens)?.length) {
                sessionStorage.setItem("okta_tokens", JSON.stringify(tokens));
                Cookies.remove("okta_tokens");
              }
            }
          }).finally(() => {
            navigate("/profile");
          });          
        })
        .finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
          setIsOpen(false);
        });
    } catch (err: any) {
      Toast.error(err?.message || err);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          title: "",
        },
      });
    }
  };

  const loadCountryOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return getCountry({
      keyword,
      page,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      sort: "name",
      direction: "asc",
    }).then((res: any) => {
      return {
        options: res?.docs?.map((country: any) => {
          return {
            value: country.pk,
            label: country?.name,
          };
        }),
        hasMore: page < res?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const loadMarketOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return getMarket({
      keyword,
      page,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      sort: "name",
      direction: "asc",
    }).then((res: any) => {
      return {
        options: res?.docs?.map((market: any) => {
          return {
            value: market.pk,
            label: joinArray([market?.country_name, market?.name], " | "),
          };
        }),
        hasMore: page < res?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size={isFirst ? "medium" : "default"}
    >
      <Modal.Body>
        <div className="flex flex-row space-x-4">
          {!isFirst && (
            <>
              <div className="rounded-full min-w-[56px] max-h-[56px] flex justify-center items-center bg-jll-surface-rag-danger bg-opacity-5">
                <SolIcon
                  icon="error"
                  size="32"
                  className="text-jll-icon-rag-danger"
                />
              </div>
              <div>
                <p>
                  You are not delegated to a specific market in order to access
                  the tool. Please click{" "}
                  <a
                    type="button"
                    target="_blank"
                    className="underline cursor-pointer"
                    onClick={handleEmail}
                  >
                    here
                  </a>{" "}
                  to request access
                </p>
              </div>
            </>
          )}
          {isFirst && (
            <div className="w-full">
              <p className="mb-4">
                You are not delegated to a specific market in order to access
                the tool.
              </p>

              <SelectAutoComplete
                label="Please select the local market(s) you support to gain access to brokers in your market"
                labelClassName="text-sm"
                required={!countryValue?.value?.length}
                size="sm"
                loadOptions={loadMarketOptions}
                value={marketValue?.value}
                isMulti
                onChange={(e) => {
                  setMarketValue((prev: any) => ({ ...prev, value: e }));
                }}
                onBlur={() =>
                  setMarketValue((prev: any) => ({ ...prev, touched: true }))
                }
                error={
                  marketValue?.touched
                    ? marketValue?.value
                      ? undefined
                      : "This field is required"
                    : undefined
                }
                name="market"
                className="w-full"
              />

              <SelectAutoComplete
                label="Alternatively, if you support a brokers across a region or country, please select countries below"
                labelClassName="text-sm"
                required={!marketValue?.value?.length}
                size="sm"
                loadOptions={loadCountryOptions}
                value={countryValue?.value}
                isMulti
                onChange={(e) => {
                  setCountryValue((prev: any) => ({ ...prev, value: e }));
                }}
                onBlur={() =>
                  setCountryValue((prev: any) => ({ ...prev, touched: true }))
                }
                error={
                  countryValue?.touched
                    ? countryValue?.value
                      ? undefined
                      : "This field is required"
                    : undefined
                }
                name="country"
                className="w-full"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 justify-end">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Close
          </SolButton>

          {isFirst && (
            <SolButton
              className="!w-[96px] sol-w-full"
              disabled={
                !!countryValue?.value?.length || !!marketValue?.value?.length
                  ? false
                  : true
              }
              onSol-click={handleSubmit}
            >
              Save
            </SolButton>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionModal;
